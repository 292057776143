/* Container with gradient background */
.form-container {
  /* margin-top: px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  background: linear-gradient(135deg, #ffffff, #ffffff);
}

/* Form box styling */
.form-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 60%;
}

/* Heading color */
.form-box h2 {
  color: #2d3748;
}

/* Label styling */
.form-box .chakra-form__label {
  font-weight: 600;
  color: #4a5568;
}

/* Input and textarea styling */
.form-box .chakra-input,
.form-box .chakra-textarea {
  background-color: #f7fafc;
  border-color: #e2e8f0;
}

/* Focus state for inputs and textareas */
.form-box .chakra-input:focus,
.form-box .chakra-textarea:focus {
  border-color: #319795;
  box-shadow: 0 0 0 1px #319795;
}

/* Submit button styling */
.submit-button {
  
  background-color: black;  /* Black background */
  color: white;             /* White text */
  border: 2px solid white;   /* White border */
  padding: 10px 20px;        /* Add padding for better spacing */
  font-size: 16px;           /* Increase the font size for better readability */
  border-radius: 8px;        /* Add rounded corners */
  cursor: pointer;           /* Change the cursor to pointer on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.submit-button:hover {
  background-color: white;   /* Switch to white background on hover */
  color: rgb(255, 255, 255);              /* Black text on hover */
  border-color: rgb(255, 255, 255);       /* Black border on hover */
}
/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .form-box {
    padding: 30px;
    margin: 0 15px;
  }
}
textarea {
  width: 100%;
  height: 100px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border: 1.5px solid rgb(0, 0, 0);
  border-radius: 4px;
}
