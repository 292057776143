/* CustomSidebar.css */
.custom-sidebar {
  font-family: 'Arial', sans-serif; /* Replace with your custom font */
  border-right: 1px solid rgb(255, 255, 255);
}

.custom-nav-item {
  font-size: 28px;              /* Custom font size */
  font-weight: bolder;            /* Bold text */
  padding: 5px;                /* Padding */
  background-color: rgb(255, 255, 255);  /* Background color */
}
